import { OverlayScrollbars } from 'overlayscrollbars';
import { DateTime } from 'luxon';
import { Notice } from './Notice';

Object.defineProperty(String.prototype, "capitalize", {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false,
  configurable: true,
});

export const scrollToSmoothly = function (pos, time = null) {
  // window.scrollTo({ top: pos });
  let currentPos = window.scrollY;
  if (currentPos === pos) return;
  let start = null;
  if (time == null) time = 500;
  pos = Number(pos);
  time = Number(time);
  window.requestAnimationFrame(function step(currentTime) {
    start = !start ? currentTime : start;
    let progress = currentTime - start;
    if (currentPos < pos) {
      window.scrollTo(0, Math.min(pos, ((pos - currentPos) * progress) / time + currentPos));
    } else if (currentPos > pos) {
      window.scrollTo(0, Math.max(pos, currentPos - ((currentPos - pos) * progress) / time));
    }
    if (progress < time) {
      window.requestAnimationFrame(step);
    } else {
      window.scrollTo(0, pos);
    }
  });
};

export const scrollToSmoothlyWithPromise = function (pos, time = null) {
  return new Promise((resolve, reject) => {

    scrollToSmoothly(pos, time);

    function isScrollingDone() {
      if (Math.abs(window.scrollY - pos) < 1) {
        resolve();
      } else {
        requestAnimationFrame(isScrollingDone);
      }
    }

    isScrollingDone();
  });
};

export const smoothScrollToElement = function(elementIdPrefix, additionalOffset = 20) {
  const targetElement = document.getElementById(elementIdPrefix);

  if (targetElement) {
    const menuElement = document.getElementById('main-header');
    const isUserWithAdminPanel = WP.user.role === 'Admin' || WP.user.role === 'Moderator' || WP.user.role === 'Redaktor';
    let offset = menuElement.clientHeight + additionalOffset; // top menu offset
    
    if (isUserWithAdminPanel) {
      offset += 32; // additional offset for admin panel
    }

    const elementTopPosition = targetElement.getBoundingClientRect().top  + window.scrollY;
    const offsetPosition = elementTopPosition - offset;

    scrollToSmoothly(offsetPosition, 300);
  }
};

let xDown = null;
let yDown = null;

function getTouches(evt) {
  return (
    evt.touches || // browser API
    evt.originalEvent.touches
  ); // jQuery
}

export const handleTouchStart = function (e) {
  const firstTouch = getTouches(e)[0];
  xDown = firstTouch.clientX;
  yDown = firstTouch.clientY;
};
/* Przykładowe użycie:
window.addEventListener('touchstart', event => {
  handleTouchStart(event);
});
window.addEventListener('touchmove', event => {
  handleTouchMove(event, () => { console.log('swipe left'); });
});
*/
export const handleTouchMove = function (e, left, right, up, down) {
  if (!xDown || !yDown) {
    return;
  }

  let xUp = e.touches[0].clientX;
  let yUp = e.touches[0].clientY;

  let xDiff = xDown - xUp;
  let yDiff = yDown - yUp;

  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) {
      /* left swipe */
      if (typeof left === "function") {
        left();
      }
    } else {
      /* right swipe */
      if (typeof right === "function") {
        right();
      }
    }
  } else {
    if (yDiff > 0) {
      /* up swipe */
      if (typeof up === "function") {
        up();
      }
    } else {
      /* down swipe */
      if (typeof down === "function") {
        down();
      }
    }
  }

  xDown = null;
  yDown = null;
};

export const isLoggedIn = function () {
  return Number(BACKEND_DATA.data.user.id) !== 0;
};

function addOrRefreshNotification(notification) {
  let index = window.notifications.findIndex(n => n.content === notification.content);
  if (index === -1 || notification.content === 'Usunięto zdarzenie z kuponu') {
    window.notifications.push(notification);
  } else {
    let notice = window.notifications[index];
    clearTimeout(notice.timeoutId);

    if (notice.dismissTime > 0) {
      notice.timeoutId = setTimeout(notice.closeFunction, notice.dismissTime);
    } else if (typeof notice.dismissTime === 'object' && Object.hasOwn(notice.dismissTime, 'mobile')
      && Object.hasOwn(notice.dismissTime, 'desktop')) {
      notice.timeoutId = setTimeout(notice.closeFunction, window.size.mobile ? notice.dismissTime.mobile : notice.dismissTime.desktop);
    }

    if (notice.refreshFunction !== null) {
      notice.refreshFunction();
    }
  }
}

export const addNotification = function (notification) {
  addOrRefreshNotification(notification);
};

export const addInternalNotification = function (notification) {
  notification.source = 'internal';
  addOrRefreshNotification(notification);
};

export const addExternalNotification = function (notification) {
  notification.source = 'external';
  addOrRefreshNotification(notification);
};

export const sendAdminNotification = function (recipientId, content, link = '') {
  return window.axios.post(window.WP.ajaxUrl, {
    endpoint: 'api_send_notification',
    recipient_id: recipientId,
    content: content,
    link: link,
  }).then(response => {
    if (response.data.success) {
      const notificationData = {
        recipientId: recipientId,
        sender_id: response.data.data.sender_id,
        seen: '0',
        created_at: DateTime.now().toFormat('yyyy-MM-dd HH:mm:ss'),
        type: 'AdminNotification',
        sender_avatar: '/wp-content/themes/zawodtyper/assets/images/zt-logo-black.webp',
        id: response.data.data.notification_id,
        comment_id: '',
        post_id: '',
        sender_name: false,
        content: content,
        link: link,
      };
      window.socket?.emit('notify-user', notificationData);
    }
  });
};

/** Returns one of the given phrases depending on the given number
 * @param number {Number,string} e.g. 5
 * @param phrases {Array.<string>} Array of strings e.g. ['osoba', 'osoby', 'osób']
 * @return {string}
 */
export let inflectNumber = function (number, phrases) {
  let n = Number(number);
  if (phrases.length < 3) {
    return 'Specify min. 3 phrases';
  }
  if (n === 1) {
    return phrases[0];
  } else if (n === 2 || n === 3 || n === 4) {
    return phrases[1];
  } else if (n >= 5 && n < 22) {
    return phrases[2];
  } else if (n % 10 === 2 || n % 10 === 3 || n % 10 === 4) {
    return phrases[1];
  } else {
    return phrases[2];
  }
};

export const openLoginModal = function () {
  document.getElementById(window.size.desktop ? 'desktop-tp-login' : 'mobile-tp-login')
    ?.getElementsByTagName('button')[0]
    ?.click();
};

export const countClicks = function (type, category = 'general', postId = 0) {
  if (!type) {
    console.log('Brak argumentu!');
    return;
  }
  return window.axios
    .post(window.WP.ajaxUrl, {
      endpoint: 'api_count_clicks',
	    category: category,
      type: type,
      post_id: postId,
    });
};

export const initializeScrollbar = function (selector, options = {
  scrollbars: {
    autoHide: 'scroll',
    theme: 'os-theme-custom',
    autoHideDelay: 1300,
  },
}) {
  return OverlayScrollbars(document.querySelector(selector), options);
};

export const stickyFollowHeader = function (stickyElId, adminBarVisible, additionalOffset = 0) {
  let sticky_scrollbar = document.getElementById(stickyElId);
  return elementStickyFollowHeader(sticky_scrollbar, adminBarVisible, additionalOffset);
};

export const elementStickyFollowHeader = function (element, adminBarVisible, additionalOffset = 0) {
  let extraTop = adminBarVisible ? window.adminBarHeight : 0;
  let mainHeaderElement = document.getElementById('main-header');

  let observer = new MutationObserver((mutations) => {
    mutations.forEach((mutationRecord) => {
      const pixelVal = extractPixelValue(mutationRecord.target.attributes.style.textContent);
      element.style.top = `${ pixelVal + extraTop + additionalOffset + window.mainHeaderHeight - 1 }px`;
    });
  });
  observer.observe(mainHeaderElement, { attributes : true, attributeFilter : ['style'] });
};

export const extractPixelValue = function (textContent) {
  let pixelVal = 0;
  const regex = /-?\d+(\.\d+)?px/g;
  const matches = textContent.match(regex);
  if (matches && matches.length > 0) {
    pixelVal = parseFloat(matches[0]);
  }
  return pixelVal;
};

export const observeEmbedded = function () {
  const elementsToObserve = document.querySelectorAll('blockquote.twitter-tweet, blockquote.instagram-media, iframe[data-src]');
  let thirdPartyObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        if (entry.target.tagName === 'BLOCKQUOTE') {
          let script = document.createElement('script');
          script.type = 'text/javascript';
          if (entry.target.classList.contains('instagram-media')) {
            script.src = '//www.instagram.com/embed.js';
          } else {
            script.src = 'https://platform.twitter.com/widgets.js';
          }
          script.charset = 'utf-8';
          script.async = true;
          entry.target.after(script);
        } else if (entry.target.tagName === 'IFRAME') {
          if (entry.target.dataset.src) {
            entry.target.src = entry.target.dataset.src;
          }
        }
        observer.unobserve(entry.target);
      }
    });
  }, {
    rootMargin: '200px 0px',
    threshold: 0.01
  });
  elementsToObserve.forEach(block => thirdPartyObserver.observe(block));
};

export const lazyLoad = function (imgs) {
  const images = imgs ?? document.querySelectorAll('img[data-srcset], img[data-src], [data-lazy-load], [data-bg-img]');
  const config = {
    rootMargin: '50px 0px',
    threshold: 0.01
  };

  let observer;

  if ('IntersectionObserver' in window) {
    observer = new IntersectionObserver(onChange, config);
    images.forEach(img => observer.observe(img));
  } else {
    console.log('%cIntersection Observers not supported', 'color: red');
    images.forEach(image => loadImage(image));
  }

  const loadImage = image => {
    if (image.dataset.src) {
      image.src = image.dataset.src;
      delete image.dataset.src;
    }
    if (image.dataset.srcset) {
      image.srcset = image.dataset.srcset;
      delete image.dataset.srcset;
    }
    if (image.dataset.bgImg) {
      image.style.backgroundImage = image.dataset.bgImg;
      delete image.dataset.bgImg;
    }

    image.classList.add('lazy-loaded');
  }

  function onChange(changes, observer) {
    changes.forEach(change => {
      if (change.isIntersecting || change.intersectionRatio > 0) {
        loadImage(change.target);
        observer.unobserve(change.target);
      }
    });
  }
};

export const toggleBackdrop = function () {
  let backdropElement = document.getElementById('backdrop');
  if (backdropElement) {
    backdropElement.remove();
  } else {
    backdropElement = document.createElement('div');
    backdropElement.id = 'backdrop';
    backdropElement.classList.add('backdrop');
    document.body.appendChild(backdropElement);
  }
};

export const sanitizeHTML = function (input, ...args) {
  return input.replace(/<(\/?)(\w+)[^>]*\/?>/g, (_, endMark, tag) => {
    return args.includes(tag) ? '<' + endMark + tag + '>' :'';
  }).replace(/<!--.*?-->/g, '');
};

export const redirectToPromoPage = function (promoUrl, shouldRedirect) {
  if (shouldRedirect) {
    window.location.href = promoUrl;
  }
};

export const formatNumberWithSeparator = function (number, separator = ' ') {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
};

export const getBigNumberFormat = function (number) {
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1) + 'M';
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1) + 'k';
  } else {
    return number.toString();
  }
};

export const getCookieValue = function (name) {
  return document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
};

export const countVisit = function () {
  if (!PHP.bot && !['Admin', 'Moderator', 'Redaktor'].includes(WP.user.role)) {
    axios.post(WP.ajaxUrl, {
      endpoint: 'api_save_visit',
      post_id: WP.id,
      browser_token: browserToken,
      path: WP.path,
      hash: window.location.hash,
      device: window.innerWidth < 768 ? 'mobile' : (window.innerWidth < 1024 ? 'tablet' : 'desktop'),
      root_category_id: WP.root_category_id,
      referrer: document.referrer,
    }).then();
  }
};

export const initializeClickableElements = function () {
	const clickableElements = document.querySelectorAll('[data-href]');
	clickableElements.forEach(el => {
		if (!el.dataset.listener) {
			const counterName = el.dataset.countClick;
			const categoryName = el.dataset.countClickCategory;
      const outerLink = el.dataset.outlink !== undefined;
			el.addEventListener('click', async (e) => {
				e.stopPropagation();
				if (counterName) {
					await axios.post(WP.ajaxUrl, {
						endpoint: 'api_count_clicks',
						type: counterName,
						post_id: 0,
						category: categoryName ?? 'general',
					});
				}
        if (outerLink) {
          await axios.post(WP.ajaxUrl, {
            endpoint: 'api_count_outerlink_click',
            link: el.dataset.href,
          });
        }
        if (el.dataset.href) {
          if (el.dataset.target) {
            window.open(el.dataset.href, el.dataset.target);
          } else {
            window.location = el.dataset.href;
          }
        }
			});
			el.dataset.listener = 'true';
		}
	});
};

export const phasePriority = ['Championship_Round', 'Conference_League_Play-off_Group', 'Relegation_Round',
  'Regular_Season', 'League_Stage', '1st_Phase', 'Eastern_Conference', 'Western_Conference',];

export const phaseDisplayInSelectPriority = ['Regular_Season', 'League_Stage', 'Championship_Round',
  'Conference_League_Play-off_Group', 'Relegation_Round', '1st_Phase', 'Eastern_Conference', 'Western_Conference'];

export const leagueNameAlternatives = {
  'La Liga': ['Primera División'],
};

export const saveShowMoreState = function (itemName, showCount, filterButtonsClicked) {
  let showMoreState = sessionStorage.getItem('showMoreState');
  if (showMoreState) {
    showMoreState = JSON.parse(showMoreState);
  } else {
    showMoreState = {};
  }
  showMoreState[itemName] = {
    showCount,
    filterButtonsClicked,
  };
  sessionStorage.setItem('showMoreState', JSON.stringify(showMoreState));
};

export const loadShowMoreState = function (itemName) {
  let showMoreState = sessionStorage.getItem('showMoreState');
  if (showMoreState) {
    showMoreState = JSON.parse(showMoreState);
    if (showMoreState[itemName]) {
      if (window.pageRefreshed) {
        delete showMoreState[itemName];
        sessionStorage.setItem('showMoreState', JSON.stringify(showMoreState));
        return null;
      }
      return showMoreState[itemName];
    }
  }
  return null;
};

export const saveScrollPositionState = function (pagePath) {
  let scrollPositions = sessionStorage.getItem('scrollPositionState');
  if (scrollPositions) {
    scrollPositions = JSON.parse(scrollPositions);
  } else {
    scrollPositions = {};
  }
  scrollPositions[pagePath] = window.scrollY;
  sessionStorage.setItem('scrollPositionState', JSON.stringify(scrollPositions));
};

export const loadScrollPositionState = function (pagePath) {
  let scrollPositions = sessionStorage.getItem('scrollPositionState');
  if (scrollPositions) {
    scrollPositions = JSON.parse(scrollPositions);
    if (scrollPositions[pagePath]) {
      setTimeout(() => {
        scrollToSmoothly(+scrollPositions[pagePath]);
      }, 500);
    }
  }
};

export const stringToId = function (text) {
  return text.toLowerCase()
    .replaceAll('ą', 'a')
    .replaceAll('ć', 'c')
    .replaceAll('ę', 'e')
    .replaceAll('ł', 'l')
    .replaceAll('ń', 'n')
    .replaceAll('ó', 'o')
    .replaceAll('ś', 's')
    .replaceAll('ź', 'z')
    .replaceAll('ż', 'z')
    .replaceAll(/[^a-z0-9]/g, '_');
};

export const saveSelectedLeague = function (selectedLeague) {
  let selectedLeagues = sessionStorage.getItem('selectedLeagues');
  if (selectedLeagues) {
    selectedLeagues = JSON.parse(selectedLeagues);
  } else {
    selectedLeagues = {};
  }
  selectedLeagues[window.location.pathname] = selectedLeague;
  sessionStorage.setItem('selectedLeagues', JSON.stringify(selectedLeagues));
};

export const loadSelectedLeague = function () {
  let selectedLeagues = sessionStorage.getItem('selectedLeagues');
  let selectedLeague = null;
  if (selectedLeagues) {
    selectedLeagues = JSON.parse(selectedLeagues);
    if (selectedLeagues[window.location.pathname]) {
      selectedLeague = selectedLeagues[window.location.pathname];
      if (window.pageRefreshed) {
        delete selectedLeagues[window.location.pathname];
        sessionStorage.setItem('selectedLeagues', JSON.stringify(selectedLeagues));
        return null;
      }
    }
  }
  return selectedLeague;
};

export const saveSelectedPeriod = function (selectedPeriod) {
  sessionStorage.setItem('selectedPeriod', selectedPeriod);
}

export const saveSelectedTeam = function (selectedTeam) {
  let selectedTeams = sessionStorage.getItem('selectedTeams');
  if (selectedTeams) {
    selectedTeams = JSON.parse(selectedTeams);
  } else {
    selectedTeams = {};
  }
  selectedTeams[window.location.pathname] = selectedTeam;
  sessionStorage.setItem('selectedTeams', JSON.stringify(selectedTeams));
};

export const loadSelectedTeam = function () {
  let selectedTeams = sessionStorage.getItem('selectedTeams');
  let selectedTeam = null;
  if (selectedTeams) {
    selectedTeams = JSON.parse(selectedTeams);
    if (selectedTeams[window.location.pathname]) {
      selectedTeam = selectedTeams[window.location.pathname];
      if (window.pageRefreshed) {
        delete selectedTeams[window.location.pathname];
        sessionStorage.setItem('selectedTeams', JSON.stringify(selectedTeams));
        return null;
      }
    }
  }
  return selectedTeam;
};